$headerHeight: 80px;
$footerHeight: 0;

$body-bg: #f9fbfd;
$color__fill-primary: #43425d;
// $color__fill-secondary: #706f91;
$color__fill-black: #1a282c;
$color__fill-white: #ffffff;
$color__fill-white-alpha-0-2: rgba($color__fill-white, 0.2);
$color__fill-beige: #e2e1cd;
$color__fill-pink: #ffa3d1;
$color__fill-brown: #db7b1c;
$color__fill-light-blue: #98dde7;
$color__fill-medium-blue: #0076c2;
$color__fill-blue: #3035ff;
$color__fill-blue-alt: #008291;
$color__fill-light-grey: #f6f6f6;
$color__fill-light-gris: #e6e9d7;
$color__fill-red: #ff542d;
$color__fill-green: #008067;
$color__fill-grey: #979797;
$color__fill-grey2: #696969;
$color__fill-yellow: #ffcd00;
$color__ui-field-background: rgba(26, 40, 44, 0.03);
$color__fill-dark-grey: #737373;
$color__fill-soft-black: #364e55;
$color__faint-grey: #f0f2f2;
$color__wild-sand-grey: #e4e2df;


// updated code color css
$sidebar__fill-color: #042338;
$primary_color: #1b75bc;
$color__fill-secondary: #69d2c9;
$color__fill-white: #ffffff;
$slider__outer-shadow: #e7e3e3;
$color__fill-black: #000000;
$color__grey-border: #c8d1e3;
$color__fill-soft-primary: #f3f9fd;
$color__fill-grey: #f5f8fe;
$color__soft-grey: #dededf;
$color__fill-menu: #646d9c;
$color__fill-primary: #43425d;
$color__active-green: #7fb440;
/*$profile__form-border: #e0e1eb;*/
$profile__form-border: #7a7a7a;
$sign__color-filter: #f5f8fe;
$color__fill-error: #9f3a38;
$color__border-black: #10090f;
$color-alpha: #114e7d;
$color__light-alpha: #002b49;
$tertiary_color: #1f3d40;


// udc variables

$udc-primary: #cf2e2e;

$md: 767px;
$sm: 600px;
$fs:991px;
$tablet:767px;
$desktop:1025px;