@import "../../Core/Style/Variables";
button.ui.button.logout-button {
  width: 100px;
  margin: 0 auto;
  display: block;
  background-color: #1e1e62cf;
  color: $color__fill-white;
}
// .ui.grid{
//     margin: 0 !important;
// }
// .ui.grid > .column:not(.row),
// .ui.grid > .row > .column {
//   // padding-left: 15px;
//   // padding-right: 15px;
// }
body {
  background: #f5f8fe;
  //   background: #000;
}
.header-review {
  margin-bottom: 30px;
}

.view-report-section {
  background: #dededf;
  border-radius: 15px;
  .progress-bar-user {
    padding: 22px;
    .your-scrore-count {
      color: #3f51b5;
      font-weight: 600;
      font-size: 40px;
    }
    .score-bar {
      margin-bottom: 5px;
      .bar {
        background: $color__fill-white;
        height: 10px;
      }
    }
  }

  .detail-user {
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    padding: 22px;
    background-color: rgb(212, 198, 198);
  }
}



.dashboard-page-udc {
  // margin-left: 250px;
  // padding: 25px;
  // margin-top: 110px;
  // top: 3%;
  // width: 82%;
  // height: 84%;
  // position: absolute;
  // left: 19%;
  // background: #f5f8fe;
  margin-top: 94px;
  // margin-left: 280px;
  // margin-left: 58px;
  height: 84%;
  background: #f5f8fe;
  .right-content-sec-all{
      // overflow: auto;
      // height: 100vh;
      padding-top:  70px !important;
        @media screen and (max-width: $desktop) {
            padding-top: 10px !important;
        }
      .text-sm-left{
        @media screen and (max-width: $desktop) {
            text-align: left;
        }
      }
      .p-3{
        padding: 0 30px 30px 30px !important;
          @media screen and (max-width: $desktop) {
            padding: 0 30px 10px 30px !important;
        }
      }
  }
  .vt-center {
    align-self: center;
    align-items: center !important;
  }
  .vt-bottom {
    align-self: flex-end;
  }
  .opted-program {
    h2 {
      margin: 18px 0;
    }
  }
  p {
    font-size: 16px;
  }
  // .ui.secondary.menu .dropdown.item:hover, .ui.secondary.menu .link.item:hover, .ui.secondary.menu a.item:hover{
  //     background: #cce0f0 !important;
  // }
  .ui.secondary.menu a.item:hover {
    background: #cce0f0 !important;
  }
  .assesment-test_sec {
    background: $color__fill-white;
    border-radius: 4px;
    padding: 22px;
    height: 470px;
    // padding: auto;
  }
  .take-test_btn {
    background-color: $primary_color !important;
    color: $color__fill-white !important;
    border: 2px solid $primary_color;
    i {
      color: $color__fill-white !important;
      opacity: 1 !important;
    }
  }
  .upcoming-meeting-bg {
    background: #e5eff7;
  }
  .take-test_btn::before {
    background-color: $color__fill-primary !important;
    color: $color__fill-white !important;
  }
  .take-test_btn::after {
    background-color: $color__fill-primary !important;
    color: $color__fill-white !important;
  }
  .grey-img-assessment {
    margin: 0;
    max-height: 259px;
    object-fit: contain;
  }
  .threesixty__logo{
    width: 98px;
    margin: auto;
  }
  .send-msg-btn {
    background-color: $color__fill-white !important;
    color: $primary_color !important;
    border: 2px solid $primary_color !important;
    // margin-left: 17px;
    // float: right;
  }
  .learn-more_link {
    color: $color__fill-secondary;
    background: transparent;
    text-decoration: underline;
  }
  .learn-more_link:hover {
    color: $color__fill-secondary !important;
    background-color: transparent !important;
  }
  .user-active {
    height: 18px;
    border: 2px solid $color__fill-white;
    width: 18px;
    background-color: $color__active-green;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    right: 60px;
    top: 0px;
  }
  .grey-heading {
    color: #3e3a3a;
    font-weight: 600;
    margin-bottom: 20px !important;
  }
  .h-fix {
    height: 425px;
    overflow: auto;
  }
  .light-bg {
    background: #cce0f0;
    height:  auto !important;

  }
}
.main-content {
  // margin-top: 100px;
  margin-right: 40px;
  // margin-left: 270px;
}
.enable-take-test {
  background: $color__fill-white;
  padding: 25px;
  border-radius: 20px;
}
.page-small{
  margin-left: 180px !important;
}
// .UDC_Sidebar.sidebar-inner:hover .dashboard-page-udc{
//   margin-right: 175px !important;
// }





.udc-home{
  p{
    font-family: 'Rubik', sans-serif !important;
  }
  button{
    border-radius: 8px;
    background-color: #ffffff;
    border: 2px solid #1b75bc;
    color: #1b75bc;
    padding: 7px 12px;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Rubik', sans-serif !important;
    cursor: pointer;
  }
  h2{
    font-family: 'Rubik', sans-serif !important;
  }
  .bg-white{
    background-color: #ffffff;
  }
  .bg-black{
    background-color: #202a31;
  }
  .p-2{
    padding: 2rem;
    @media screen and (max-width: $desktop) {
      padding: 1rem;
    }
  }
  .mb-2{
    margin-bottom: 2rem;
  }
  .mb-1{
    margin-bottom: 1rem;
  }
  .progress-bar{
    height: auto !important;
    width: 100% !important;
    h3{
      color: #ffffff;
      font-size: 20px;
      margin: 0;
    }
    .d-flex{
      display: flex;
    }
    .align-center{
      align-items: center;
    }
    .flex-80{
      width: 80%;
    }
    .ui.progress{
      background: #cecfd0;
      height: 12px;
      border-radius: 6px;
      margin: 0;
      .bar{
        background: #69d2c9;
        height: 12px;
        border-radius: 6px;
      }
    }
  }
  .demo-row{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    color: #202a31;
    font-weight: 600;
    button{
      font-size: 14px;
      padding: 4px 8px;
    }
  }
  .btn-primary{
    background-color: #1b75bc;
    color: #ffffff;
  }
    ul{
      display: flex;
      list-style: none;
      position: relative;
      &::before{
            content: '';
            width: 1px;
            position: absolute;
            height: 100%;
            background: #202a31;
            left: 33px;
            // z-index: -1;
          }
      li{
        &:nth-child(1){
          width: 9%;
          position: relative;
          padding-bottom: 60px;
          z-index: 0;
          @media screen and (max-width: 1200px) {
             width: 23%;
          }
          // &::before{
          //   content: '';
          //   width: 2px;
          //   position: absolute;
          //   height: 100%;
          //   background: #202a31;
          //   left: 37%;
          //   z-index: -1;
          // }
        }
        &:nth-child(2){
          width: 80%;
          padding-bottom: 60px;
          padding-top: 20px;
        }
        img{
          border-radius: 8px;
        }
        &::before{
          content: '';
          width: 2px;
        }
      }
      .align-center{
        align-items: center;
      }
      .count{
            border-radius: 38px;
            color: #202a31;
            background-color: #e0e7f5;
            width: 66px;
            height: 66px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 36px;
            @media screen and (max-width: $desktop) {
              width: 55px;
              height: 55px;
            }
            &.active{
              background-color: #ffffff;
              border: 2px solid #69d2c9;
              color: #69d2c9;
            }
            &.assessment-completed{
              background-color: #69d2c9;
              color:  #ffffff;
            }
      }
      &:nth-child(3){
        &::before{
          display: none;
        }
      }
    }
    
    .badge{
          font-size: 12px;
          line-height: 16px;
          font-weight: 600;
          padding: 3px 7px;
          border-radius: 4px;
          margin-left: 4px;
    }
    .badge-black{
      background-color: #202a31;
      color: #ffffff;
    }
    .badge-black-outlined{
      border: 1px solid #202a31;
    }
    .badge-warning{
      background-color: #ffa348;
      border: 1px solid #ffa348;
    }
    .badge-green{
      background-color: #69d2c9;
      border: 1px solid #69d2c9;
    }
    .finish-btn{
      border: 2px solid #69d2c9;
      color: #69d2c9;
    }
    .black-btn{
      border: 2px solid transparent;
      color: #202a31;
    }

    
}