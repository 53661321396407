@import "../../Core/Style/Variables.scss";

.modal-padding{
    // padding: 3rem 2rem 1rem 2rem;
    font-size: 15px;
   
    
}
.chat-form{
    form{
        background-color:#E4FCFA;
        border-radius: 1rem;
        padding: 1rem 3rem;
        width: 80%;
        margin: auto;
        @media screen and (max-width: $sm){
            width: 100%;
        }
    }
}
h6{
    font-size: 20px;
    padding-bottom: 15px;
}

.section-padding{

   
    padding: 1.5rem 0;
    img{
        width: 200px;
        margin-bottom: 12px;
    }

    
    textarea{
        border: 2px solid #7a7a7a !important;
        }
}
.section-padding-second{
    display: flex;
    align-items: center;
    height: 78%;
    img{
        width: 200px;
        margin-bottom: 12px;
    }
}

.text-right{
    text-align: right;
}

.content{
    .header{
        font-weight: bold;
        margin-bottom: 5px;
    }
    .summary{
        line-height: 1.5;
    }
}



