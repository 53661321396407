@import "Core/Style/Variables.scss";

h1,
h2,
h3,
h4,
h6,
button {
  font-family: "Proxima Nova", sans-serif !important;
}
body {
  // background: #f5f8fe;
  background: $body-bg;
  //   background: #000;
}
.react-tel-input {
  input.form-control {
    padding-left: 45px !important;
    height: 48px;
    border: 2px solid $profile__form-border !important;
  }
}
.menus-header-login {
  .item {
    font-size: 16px !important;
    cursor: pointer;
  }
  .header {
    color: $color__fill-menu !important;
    font-weight: 500 !important;
    margin: 0 10px !important;
  }
}
.ui.simple.active.dropdown > .menu,
.ui.simple.dropdown:hover > .menu {
  top: 150% !important;
}
h1.ui.header {
  color: $tertiary_color !important;
  font-size: 55px !important;
  line-height: 60px !important;
  font-weight: 800 !important;
}

.ui.form {
  margin-bottom: 10px !important;
}
.MuiStepIcon-root.MuiStepIcon-active {
  color: $color__light-alpha !important;
}
.MuiStepIcon-text {
  fill: $color-alpha !important;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
@media (max-width: 480px) {
  .ui.secondary.vertical.sidebar-inner.menu {
    width: 70px !important;
  }
  .Admin_Table th {
    min-width: 100px !important;
  }
  .table-responsive {
    overflow-x: auto !important;
  }
  .clear-filter-btn {
    position: unset !important;
  }
  .first-category-question {
    width: 100% !important;
  }
  .dashboard-page {
    // margin-left: 170px !important;
  }
  .header-sec .my-profile-img {
    width: 40px !important;
    height: 43px !important;
  }
  .image-edit button.ui.button.edit-button {
    margin-left: 0 !important;
  }
  .image-edit .image-upload img.ui.image.image-user {
    width: 85px !important;
    height: auto !important;
  }
  .header-sec .ui.text.menu-header.menu .dropdown-header {
    margin-left: 0 !important;
  }
  .main-content {
    margin-right: 0px !important;
  }
  .header-sec .ui.text.menu-header.menu .dropdown-header {
    font-size: 9px !important;
  }
  .header-sec .notification-bg {
    font-size: 9px !important;
  }
  .header-sec .ui.text.menu-header.menu {
    margin-right: 0 !important;
  }
  .ui.secondary.vertical.sidebar-inner.menu {
    top: 86px !important;
  }
  .sign-page-filter .image-user {
    margin-bottom: 7px !important;
  }
  p {
    font-size: 14px !important;
  }
  h2 {
    font-size: 14px !important;
  }
  .txt-label {
    font-weight: 400;
    font-size: 18px;
    color: #4c4c4c;
  }
  .learning-programs-page-inner {
    padding: 5px 5px !important;
  }
  .CardList {
    padding: 0 !important;
  }
  .learning-programs-page-inner .ui.secondary.menu {
    display: contents !important;
  }
  .header-sec .notification-bg {
    padding: 9px 6px !important;
  }
  .learning-programs-page-inner .ui.secondary.menu .item {
    font-size: 10px !important;
  }
  .learning-programs-page-inner .ui.secondary.menu .active.item {
    font-size: 10px !important;
  }
  .display-grid-mb {
    display: block !important;
    button {
      margin-bottom: 5px !important;
      margin-top: 5px !important;
    }
  }
  .learning_program-sec .program__continue-btn {
    margin-top: 0 !important;
  }
  .header-sec .notification-bg {
    padding: 9px 10px !important;
    margin-right: 5px !important;
  }
  // .save-draft-sec{
  //   text-align: left !important;
  // }
  .save-draft-sec button {
    margin-bottom: 5px !important;
  }
  .header-sec .ui.text.menu-header.menu .dropdown-header {
    margin-top: 11px !important;
  }
  .header-sec .logo-img {
    padding-top: 2px !important;
    margin-left: 22px !important;
    max-width: 135% !important;
  }
  .message-activity_sec {
    .ui.secondary.menu .active.item {
      font-size: 11px !important;
    }
    .ui.secondary.menu .item {
      font-size: 11px !important;
    }
  }
  .step-dot-img img {
    width: 100% !important;
  }
}
@media (min-width: 1024px) {
  .pro-full-width {
    width: 100% !important;
  }
  .ballon-box-conainter {
    width: auto;
  }
  .top_video {
    width: 100% !important;
    height: 100% !important;
  }
  // .set-right-img{
  //   margin-left: 0rem !important;
  //   width: 47% !important;
  // }
}
@media (max-width: 1024px) {
  .set-right-img {
    margin-left: 0rem !important;
    width: 47% !important;
  }
  .home-top-section {
    padding: 39px 0 5px 0 !important;
  }
  .login-inner-sec {
    width: 100% !important;
  }
}

// @media (min-width:767px){
//   .set-right-img{
//     margin-left: 0rem !important;
//   }
// }

@media only screen and (max-width: 767px) {
  .ui.form .fields {
    margin: 0 -0.5em 1em !important;
  }
}
@media (max-width: 480px) {
  .set-right-img {
    display: none;
  }
  .home-page p {
    font-size: 1rem !important;
  }
  .home-page h1.ui.header {
    font-size: 22px !important;
    line-height: 27px !important;
  }
  .top-inner-sec h1.ui.header {
    font-size: 24px !important;
    line-height: 26px !important;
  }
  .about-censio h1.ui.header {
    font-size: 24px !important;
    line-height: 26px !important;
  }
  .today-sec h1.ui.header {
    font-size: 24px !important;
    line-height: 26px !important;
  }
  .different-sec h1.ui.header {
    font-size: 24px !important;
    line-height: 26px !important;
  }
  .science-sec h1.ui.header {
    font-size: 24px !important;
    line-height: 26px !important;
  }
  .educators h1.ui.header {
    font-size: 24px !important;
    line-height: 26px !important;
  }
  .home-top-section p {
    font-size: 1rem !important;
  }
  .home-top-section {
    padding: 0px 0 1px 0 !important;
  }
  .white-shape-bg .step-dot-img {
    margin: 4rem 0 0 0 !important;
  }
  .home-top-section .top-inner-sec {
    margin: 2rem 0 !important;
  }
  .mission-sec .mg-negative {
    margin-top: 0 !important;
  }
}

@media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
  .dashboard-page .pro-full-width {
    width: 100% !important;
  }
  .message-activity_sec .doc-box {
    margin: 0 4px 0 4px;
  }
  .message-activity_sec .doc-name-txt {
    font-size: 14px !important;
    margin: 7px 4px 0 4px !important;
  }
}
@media (max-width: 320px) {
  .header-sec .notification-bg {
    padding: 6px 8px !important;
  }
  .display-content-mob {
    display: contents !important;
  }
}
.ui.container {
  width: 1485px !important;
  @media screen and (max-width: $sm) {
    width: auto !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ui.container {
    width: 933px !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ui.container {
    width: 723px !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
@media only screen and (min-width: 1200px) {
  .ui.container {
    width: 1127px !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
@media only screen and (min-width: 1400px) {
  .ui.container {
    width: 1388px !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
@media only screen and (min-width: 1900px) {
  .ui.container {
    width: 1485px !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
@media only screen and (max-width: 1400px) {
  input {
    padding: 8px 6px !important;
    height: 30px !important;
  }
  .sign-page-filter .pr-btn-sm {
    font-size: 0.9rem !important;
    padding: 7px 12px !important;
    margin-top: 0 !important;
  }
  .forgot-inner-sec .primary-btn {
    font-size: 1.2rem !important;
    padding: 7px 12px !important;
    margin-top: 0 !important;
  }
  .question-register-page-form .primary-btn {
    font-size: 0.7rem !important;
    padding: 5px 12px !important;
    margin-top: 0 !important;
  }
  .sign-page-filter .primary-btn {
    font-size: 0.8rem !important;
    padding: 6px 12px !important;
    margin-top: 0 !important;
  }
  .login-inner-sec .primary-btn {
    font-size: 1.2rem !important;
    padding: 7px 12px !important;
  }
  .show-txt-input {
    height: 32px;
    padding: 12px 20px 10px 40px !important;
    font-size: 0.7em !important;
  }
  .checkmark-password {
    top: 7px !important;
    height: 16px !important;
    width: 16px !important;
  }
  .register-page p {
    font-size: 1em !important;
  }
  .login-content-sec .login-inner-sec p {
    font-size: 1.1rem !important;
  }
  .question-register-page-form select {
    padding: 6px 14px !important;
  }
  .sign-page-filter label {
    font-size: 0.8em !important;
  }
  .sign-page-filter input {
    font-size: 0.7em !important;
  }
  .login-content-sec label {
    font-size: 0.8em !important;
  }
  .login-content-sec input {
    font-size: 0.8em !important;
  }
  .ui.form select {
    font-size: 0.8em !important;
    height: 33px !important;
    padding: 0 0 !important;
  }
  .agree-txt-input {
    font-size: 9px !important;
    padding: 4px 20px 0px 24px !important;
    line-height: 1.8 !important;
  }
  .checkmark-agree {
    top: 6px !important;
    height: 14px !important;
    width: 14px !important;
  }
  .question-register-page-form .fields {
    margin: 0 -0.5em 0.1em !important;
  }
  .stepper-register-form .accomplished {
    height: 20px !important;
    width: 20px !important;
  }
  .stepper-register-form .null {
    height: 20px !important;
    width: 20px !important;
  }
  .RSPBprogressBar {
    margin: 14px auto 10px auto !important;
  }
  .stepper-register-form .RSPBstep {
    width: 35px !important;
  }
  .password-rule-list li {
    font-size: 9px !important;
    line-height: 1.8 !important;
  }
  .password-rule-list i {
    font-size: 8px !important;
  }
  .social-login img {
    width: 30px !important;
  }
  .sign-page-filter .fields {
    margin-bottom: 3px !important;
  }
  .profile-form select {
    height: 30px !important;
  }
  .profile-page-form label {
    font-size: 0.6em !important;
  }
  .profile-page-form input {
    font-size: 0.6em !important;
  }
  .profile-page-form .fields {
    margin: 0 -0.5em 0.1em !important;
  }
  .profile-popup-page-form label {
    font-size: 0.6em !important;
  }
  .profile-popup-page-form input {
    font-size: 0.6em !important;
  }
  .profile-popup-page-form .fields {
    margin: 0 -0.5em 0.1em !important;
  }

 

  .register-progress .accomplished {
    width: 20px;
    height: 20px;
    font-size: 10px;
  }
  .register-progress .null {
    width: 20px;
    height: 20px;
    font-size: 10px;
  }
  .register-progress .RSPBstep {
    width: 32px;
  }
  input:checked ~ .checkmark-password::after {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 12px !important;
  }
  .login-sub-sec__inner-container .login-footer .txt-12 {
    font-size: 9px !important;
  }
  .register-page-content-area .login-footer .txt-12 {
    font-size: 9px !important;
  }
  .login-footer {
    
  .txt-12 {
    font-size: 9px !important;
  }
  }
  .forgot-content-sec p {
    font-size: 1.2rem !important;
  }
  .Admin_Table th {
    font-size: 11px !important;
    padding: 3px 5px !important;
  }
  .Admin_Table td {
    font-size: 10px !important;
    padding: 2px 8px !important;
  }
  .filter_admin-row button {
    top: 12px !important;
  }
  .Admin_Table .filter_admin-row select {
    font-size: 10px !important;
  }
  .Admin_Table .filter_admin-row input {
    font-size: 10px !important;
  }
  .admin-table-com .active-pagination-footer-btn {
    font-size: 12px !important;
  }
  .admin-table-com .disable-pagination-footer-btn {
    font-size: 12px !important;
  }
  .admin-table-com .active-footer-btn {
    font-size: 12px !important;
  }
  .admin-table-com .disable-footer-btn {
    font-size: 12px !important;
  }
  .admin_user-table th:first-child input {
    // width: 43% !important;
    margin: 0 3px 0 0 !important;
  }
  .profile-popup-before-icon {
    right: 47% !important;
  }
}

.notificationModal {
  // padding: 25px;
  //   text-align: center;
  //   width: 100%;
  //   height: 100%;
  //   background-color: #fff;
  //   color: black;
  //   position: fixed;
  //   z-index: 1;
  //   background-color: rgba(0,0,0, 0.4)
  padding: 0px;
  text-align: center;
  color: black;
  position: fixed;
  width: 100%;
  margin-left: auto;
  right: 0;
  overflow: auto;
  height: 28px;
  top: 0;
  z-index: 999;
}

// .sub-modal {
//   // min-width: 600px;
//   // background-color: white;
//   // margin: auto;
//   // max-height: 600px;
//   // padding: 50px 20px;
//   // border-radius: 8px;
//   // max-width: 600px;
//   // overflow: auto;
// }

.dialog {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.notificationModal button {
  border: none;
  background-color: #1b75bc;
  color: #fff;
  font-size: 22px;
  border-radius: 6px;
  padding: 15px 35px;
  font-weight: bold;
  box-shadow: 0 2px 3px #1b75bc;
  margin-top: 20px;
}

.notificationModal h2 {
  color: #fff;
  font-size: 16px;
  text-align: left;
  margin: 0;
}
.bg-message-error {
  background-color: red;
}

.bg-message-success {
  background-color: green;
}

.register-page-header {
  position: relative;
  // z-index: 000;
}

.sub-heading {
  font-size: 30px !important;
}
.txt-12 {
  font-size: 12px !important;
}
.txt-14 {
  font-size: 14px !important;
}
.login-footer {
  text-align: center;
}
.react-datepicker__year-read-view--down-arrow {
  top: 2px !important;
}
.mb-10 {
  margin-bottom: 10px;
}
.btn-sm {
  background-color: #1b75bc !important;
  color: #fff !important;
  padding: 5px 8px;
  border-radius: 5px;
  font-weight: bold;
  background-image: linear-gradient(
    hsla(0, 0%, 100%, 0.6),
    hsla(0, 0%, 100%, 0) 50%,
    hsla(0, 0%, 0%, 0.3) 50%,
    hsla(0, 0%, 100%, 0.2)
  );
  border-radius: 20px;
}
.btn-sm::before {
  content: "";
  display: block;
  position: absolute;
  left: 20px;
  right: 20px;
  top: 5px;
  // height: 30px;
  border-radius: 15px;
  background: linear-gradient(hsla(0, 0%, 100%, 0.8), hsla(0, 0%, 100%, 0));
}
.d-flex {
  display: flex;
}


// .login-bg-new { 
//       margin-left: 175px !important;
// }

@media only screen and (max-width: 767px){
  .ui.container {
      width: auto!important;
      margin-left: 1em!important;
      margin-right: 1em!important;
  }
}